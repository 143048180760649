export const parseUKDate = (dateString) =>{
  const [day, month, year] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);  // months are 0-based in JS Date
}


export const isPublishedToday = (word) => {
  const currentDate = new Date();
    const publishDate = parseUKDate(word.published);

    return currentDate.getDate() === publishDate.getDate() &&
           currentDate.getMonth() === publishDate.getMonth() &&
           currentDate.getFullYear() === publishDate.getFullYear();
}


export const sample = (words) => {
  const todayPublishedWords = words.filter(isPublishedToday);
    if (todayPublishedWords.length === 0) {
      throw new Error("No words have been published today!");
    }
    const randomIndex = Math.floor(Math.random() * todayPublishedWords.length);
    return todayPublishedWords[randomIndex].title;
}


export const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};
