import React from 'react';
import { sample } from '../../utils';
import { WORDS } from '../../data2';
import { NUM_OF_GUESSES_ALLOWED } from '../../constants';
import { checkGuess } from '../../game-helpers';

import GuessInput from '../GuessInput/GuessInput';
import GuessResults from '../GuessResults/GuessResults';
import WonBanner from '../WonBanner';
import LostBanner from '../LostBanner';
import Keyboard from '../Keyboard';
import Footer from '../Footer';
import Message from '../Message/Message';

function Game() {
  const [answer, setAnswer] = React.useState(() => {
    try {
      const sampledWord = sample(WORDS);
      if (!sampledWord) throw new Error('No valid words available');
      return sampledWord;
    } catch (error) {
      console.error(error.message);
      return null;
    }
  });

  const [guesses, setGuesses] = React.useState([]);
  const [currentGuess, setCurrentGuess] = React.useState(''); // State for the current guess
  const [gameStatus, setGameStatus] = React.useState('running');

  function handleRestart() {
    try {
      const newAnswer = sample(WORDS);
      if (!newAnswer) throw new Error('No valid words available');
      setAnswer(newAnswer);
      setGuesses([]);
      setCurrentGuess(''); // Reset the current guess
      setGameStatus('running');

    } catch (error) {
      console.error(error.message);
      setAnswer(null);
    }
  }

  const validatedGuesses = guesses.map((guess) =>
    checkGuess(guess, answer)
  );

  function handleSubmitGuess(tentativeGuess) {
    const nextGuesses = [...guesses, tentativeGuess];
    setGuesses(nextGuesses);
    setCurrentGuess(''); // Reset current guess after submission

    if (tentativeGuess === answer.toString()) {
      setGameStatus("won");
    } else if (nextGuesses.length >= NUM_OF_GUESSES_ALLOWED) {
      setGameStatus("lost");
    } else {
      setGameStatus("running");
    }
  }

  function handleLetterInput(letter) {
    // Handle input from the on-screen keyboard
    if (currentGuess.length < 6) {
      setCurrentGuess(currentGuess + letter);
    }
  }

  if (answer === null) {
    return <Message />;
  }

  return (
    <>
      <GuessResults guesses={guesses} answer={answer} />
      <GuessInput
        gameStatus={gameStatus}
        handleSubmitGuess={handleSubmitGuess}
        currentGuess={currentGuess}
        setCurrentGuess={setCurrentGuess}  // Pass setter to update from GuessInput
      />
      {gameStatus === 'won' && (
        <WonBanner numOfGuesses={guesses.length} handleRestart={handleRestart} />
      )}
      {gameStatus === 'lost' && <LostBanner answer={answer} />}
      <Keyboard
        validatedGuesses={validatedGuesses}
        handleLetterInput={handleLetterInput}  // Pass handler for keyboard input
      />
      <Footer />
    </>
  );
}

export default Game;
