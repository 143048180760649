import React from 'react';
import * as style from "./Message.module.css"

const Message = () => {
  return (
    <div className={styles.message}>
      <strong>Uh-oh!</strong> It looks like we are out of words.
    </div>
  );
}

export default Message;
