import React from "react";
import { NUM_OF_GUESSES_ALLOWED } from "../../constants";

function GuessInput({ handleSubmitGuess, gameStatus, currentGuess, setCurrentGuess }) {

  function handleSubmit(event) {
    event.preventDefault();

    const lettersOnly = /^[a-zA-Z]{6}$/;  // Assuming the game uses 5-letter words
    const length = /^.{6}$/;

    if (!length.test(currentGuess)) {
      alert("Input has to be five letters long.");
      return;
    }

    if (!lettersOnly.test(currentGuess)) {
      alert("Input has to be letters only.");
      return;
    }
    handleSubmitGuess(currentGuess);
    setCurrentGuess('');

  }

  return (
    <div>
      <form className='guess-input-wrapper' onSubmit={handleSubmit}>
        <label htmlFor='guess-input'>Enter guess:</label>
        <input
          id='guess-input'
          type='text'
          value={currentGuess}
          onChange={(event) => {
            setCurrentGuess(event.target.value.toUpperCase());
          }}
          disabled={gameStatus !== "running"}
        />
      </form>
    </div>
  );
}

export default GuessInput;
