export const WORDS = [
  { title: "FAMILY", published: "10-09-2023" },
  { title: "AERATE", published: "11-09-2023" },
  { title: "BAFFLE", published: "12-09-2023" },
  { title: "CAVERN", published: "13-09-2023" },
  { title: "DABBLE", published: "14-09-2023" },
  { title: "COOKIE", published: "15-09-2023" },
  { title: "MALLET", published: "16-09-2023" },
  { title: "GARNER", published: "17-09-2023" },
  { title: "HAGGLE", published: "18-09-2023" },
  { title: "INHALE", published: "19-09-2023" },
  { title: "JANGLE", published: "20-09-2023" },
  { title: "HAPTIC", published: "21-09-2023" },
  { title: "LADDER", published: "22-09-2023" },
  { title: "MANGLE", published: "23-09-2023" },
  { title: "NAGGER", published: "24-09-2023" },
  { title: "FROZEN", published: "25-09-2023" },
  { title: "PARLEY", published: "26-09-2023" },
  { title: "QUORUM", published: "27-09-2023" },
  { title: "RAVINE", published: "28-09-2023" },
  { title: "SADDLE", published: "29-09-2023" },
  { title: "TANGLE", published: "30-09-2023" },
  { title: "UNREAD", published: "01-10-2023" },
  { title: "VANISH", published: "02-10-2023" },
  { title: "WAFFLE", published: "03-10-2023" },
  { title: "PENCIL", published: "04-10-2023" },
  { title: "ICICLE", published: "05-10-2023" },
  { title: "RATTLE", published: "06-10-2023" },
  { title: "AFFIRM", published: "07-10-2023" },
  { title: "BOGGLE", published: "08-10-2023" },
  { title: "CADDIE", published: "09-10-2023" },
  { title: "DEAFEN", published: "10-10-2023" },
  { title: "ENGAGE", published: "11-10-2023" },
  { title: "FLANGE", published: "12-10-2023" },
  { title: "GRILLE", published: "13-10-2023" },
  { title: "HOBBLE", published: "14-10-2023" },
  { title: "IMPART", published: "15-10-2023" },
  { title: "JOGGER", published: "16-10-2023" },
  { title: "KIDNEY", published: "17-10-2023" },
  { title: "VIRTUE", published: "18-10-2023" },
  { title: "MIRROR", published: "19-10-2023" },
  { title: "NOBBLE", published: "20-10-2023" },
  { title: "OCCURS", published: "21-10-2023" },
  { title: "PURSUE", published: "22-10-2023" },
  { title: "QUAVER", published: "23-10-2023" },
  { title: "RIDDLE", published: "24-10-2023" },
  { title: "SILVER", published: "25-10-2023" },
  { title: "TICKER", published: "26-10-2023" },
  { title: "UPBEAT", published: "27-10-2023" },
  { title: "VEXING", published: "28-10-2023" },
  { title: "WARMER", published: "29-10-2023" },
  { title: "MINGLE", published: "30-10-2023" },
  { title: "YELLOW", published: "31-10-2023" },
  { title: "ZOOMED", published: "01-11-2023" },
  { title: "TRAVEL", published: "02-11-2023" },
  { title: "MEDIUM", published: "03-11-2023" },
  { title: "BRANCH", published: "04-11-2023" },
  { title: "ORIGIN", published: "05-11-2023" },
  { title: "SHADOW", published: "06-11-2023" },
  { title: "DESIGN", published: "07-11-2023" },
  { title: "LIQUID", published: "08-11-2023" },
  { title: "FORMAT", published: "09-11-2023" },
  { title: "SPRING", published: "10-11-2023" },
  { title: "SILVER", published: "11-11-2023" },
  { title: "MARGIN", published: "12-11-2023" },
  { title: "ACTUAL", published: "13-11-2023" },
  { title: "ETHNIC", published: "14-11-2023" },
  { title: "BEHAVE", published: "15-11-2023" },
  { title: "LEGEND", published: "16-11-2023" },
  { title: "ISLAND", published: "17-11-2023" },
  { title: "FORMAL", published: "18-11-2023" },
  { title: "BEFORE", published: "19-11-2023" },
  { title: "DAMAGE", published: "20-11-2023" },
  { title: "ADVICE", published: "21-11-2023" },
  { title: "BRIGHT", published: "22-11-2023" },
  { title: "MEMORY", published: "23-11-2023" },
  { title: "OBJECT", published: "24-11-2023" },
  { title: "MODIFY", published: "25-11-2023" },
  { title: "NEARLY", published: "26-11-2023" },
  { title: "WINDOW", published: "27-11-2023" },
  { title: "SIMPLE", published: "28-11-2023" },
  { title: "STATIC", published: "29-11-2023" },
  { title: "STREAM", published: "30-11-2023" },
  { title: "VISUAL", published: "01-12-2023" },
  { title: "FACADE", published: "02-12-2023" },
  { title: "GANDER", published: "03-12-2023" },
  { title: "JABBER", published: "04-12-2023" },
  { title: "KNEADS", published: "05-12-2023" },
  { title: "LATHER", published: "06-12-2023" },
  { title: "NUZZLE", published: "07-12-2023" },
  { title: "OMELET", published: "08-12-2023" },
  { title: "PADDLE", published: "09-12-2023" },
  { title: "QUIVER", published: "10-12-2023" },
  { title: "UPLIFT", published: "11-12-2023" },
  { title: "VERBAL", published: "12-12-2023" },
  { title: "WALLET", published: "13-12-2023" },
  { title: "YONDER", published: "14-12-2023" },
  { title: "ZEPHYR", published: "15-12-2023" },
  { title: "ABSORB", published: "16-12-2023" },
  { title: "BANISH", published: "17-12-2023" },
  { title: "CUDDLE", published: "18-12-2023" },
  { title: "DANGLE", published: "19-12-2023" },
  { title: "EXPOSE", published: "20-12-2023" },
  { title: "FICKLE", published: "21-12-2023" },
  { title: "GRAVEL", published: "22-12-2023" },
  { title: "HURDLE", published: "23-12-2023" },
  { title: "JUGGLE", published: "24-12-2023" },
  { title: "KINDLE", published: "25-12-2023" },
  { title: "LOOMED", published: "26-12-2023" },
  { title: "MUZZLE", published: "27-12-2023" },
  { title: "NIBBLE", published: "28-12-2023" },
  { title: "BRICKS", published: "29-12-2023" },
  { title: "DRENCH", published: "30-12-2023" },
  { title: "FLINCH", published: "31-12-2023" },
  { title: "GROOVE", published: "01-01-2024" },
  { title: "PUDDLE", published: "02-01-2024" },
  { title: "QUENCH", published: "03-01-2024" },
  { title: "RUMBLE", published: "04-01-2024" },
  { title: "STIFLE", published: "05-01-2024" },
  { title: "TUMBLE", published: "06-01-2024" },
  { title: "UNRULY", published: "07-01-2024" },
  { title: "WOBBLE", published: "08-01-2024" },
  { title: "YAWNED", published: "09-01-2024" },
  { title: "KERNEL", published: "10-01-2024" },
  { title: "LUMBER", published: "11-01-2024" },
  { title: "MARVEL", published: "12-01-2024" },
  { title: "NESTLE", published: "13-01-2024" },
  { title: "ABACAS", published: "14-01-2024" },
  { title: "PACKET", published: "15-01-2024" },
  { title: "QUEASY", published: "16-01-2024" },
  { title: "RECIPE", published: "17-01-2024" },
  { title: "SCHOOL", published: "18-01-2024" },
  { title: "TINKER", published: "19-01-2024" },
  { title: "UNVEIL", published: "20-01-2024" },
  { title: "VORTEX", published: "21-01-2024" },
  { title: "WIDGET", published: "22-01-2024" },
  { title: "YEARLY", published: "23-01-2024" },
  { title: "ZENITH", published: "24-01-2024" },
  { title: "ABOUND", published: "25-01-2024" },
  { title: "BUCKET", published: "26-01-2024" },
  { title: "CHORUS", published: "27-01-2024" },
  { title: "DRAGON", published: "28-01-2024" },
  { title: "EFFORT", published: "29-01-2024" },
  { title: "FRINGE", published: "30-01-2024" },
  { title: "GLITCH", published: "31-01-2024" },
  { title: "HUMBLE", published: "01-02-2024" },
  { title: "INVOKE", published: "02-02-2024" },
  { title: "JOSTLE", published: "03-02-2024" },
  { title: "KNIGHT", published: "04-02-2024" },
  { title: "LEVITY", published: "05-02-2024" },
  { title: "MOTIVE", published: "06-02-2024" },
  { title: "NOTION", published: "07-02-2024" },
  { title: "OUTFOX", published: "08-02-2024" },
  { title: "PLAGUE", published: "09-02-2024" },
  { title: "QUOTED", published: "10-02-2024" },
  { title: "RESORT", published: "11-02-2024" },
  { title: "SPIRAL", published: "12-02-2024" },
  { title: "THRONG", published: "13-02-2024" },
  { title: "VACANT", published: "14-02-2024" },
  { title: "WITHER", published: "15-02-2024" },
  { title: "JARGON", published: "16-02-2024" },
  { title: "ZODIAC", published: "17-02-2024" },
  { title: "ARCHER", published: "18-02-2024" },
  { title: "BREEZE", published: "19-02-2024" },
  { title: "CRUNCH", published: "20-02-2024" },
  { title: "DEPLOY", published: "21-02-2024" },
  { title: "EMBARK", published: "22-02-2024" },
  { title: "AUTHOR", published: "23-02-2024" },
  { title: "BEACON", published: "24-02-2024" },
  { title: "CIRCLE", published: "25-02-2024" },
  { title: "DREAMY", published: "26-02-2024" },
  { title: "EMBLEM", published: "27-02-2024" },
  { title: "GUILTY", published: "28-02-2024" },
  { title: "HARBOR", published: "29-02-2024" },
  { title: "IGNORE", published: "01-03-2024" },
  { title: "LAUNCH", published: "02-03-2024" },
  { title: "MARKET", published: "03-03-2024" },
  { title: "NATURE", published: "04-03-2024" },
  { title: "ORANGE", published: "05-03-2024" },
  { title: "PEOPLE", published: "06-03-2024" },
  { title: "QUARRY", published: "07-03-2024" },
  { title: "RECORD", published: "08-03-2024" },
  { title: "SEASON", published: "09-03-2024" },
  { title: "THEORY", published: "10-03-2024" },
  { title: "UNIQUE", published: "11-03-2024" },
  { title: "VACUUM", published: "12-03-2024" },
  { title: "WEALTH", published: "13-03-2024" },
  { title: "YELLOW", published: "14-03-2024" },
  { title: "ANIMAL", published: "15-03-2024" },
  { title: "CANCEL", published: "16-03-2024" },
  { title: "DIRECT", published: "17-03-2024" },
  { title: "EASILY", published: "18-03-2024" },
  { title: "FUTURE", published: "19-03-2024" },
  { title: "GARDEN", published: "20-03-2024" },
  { title: "HEALTH", published: "21-03-2024" },
  { title: "INCOME", published: "22-03-2024" },
  { title: "JOINED", published: "23-03-2024" },
  { title: "KEEPER", published: "24-03-2024" },
  { title: "LATEST", published: "25-03-2024" },
  { title: "MOBILE", published: "26-03-2024" },
  { title: "NOTICE", published: "27-03-2024" },
  { title: "OFFICE", published: "28-03-2024" },
  { title: "PLENTY", published: "29-03-2024" },
  { title: "REMOTE", published: "30-03-2024" },
  { title: "SEARCH", published: "31-03-2024" },
  { title: "TOWARD", published: "01-04-2024" },
  { title: "UPDATE", published: "02-04-2024" },
  { title: "VISION", published: "03-04-2024" },
  { title: "WORKER", published: "04-04-2024" },
  { title: "ACCEPT", published: "05-04-2024" },
  { title: "BANANA", published: "06-04-2024" },
  { title: "CASUAL", published: "07-04-2024" },
  { title: "DINNER", published: "08-04-2024" },
  { title: "EARNED", published: "09-04-2024" },
  { title: "FLOWER", published: "10-04-2024" },
  { title: "GENTLE", published: "11-04-2024" },
  { title: "HONEST", published: "12-04-2024" },
  { title: "IMPORT", published: "13-04-2024" },
  { title: "JUMPER", published: "14-04-2024" },
  { title: "KETTLE", published: "15-04-2024" },
  { title: "LINGER", published: "16-04-2024" },
  { title: "MUSCLE", published: "17-04-2024" },
  { title: "NICKEL", published: "18-04-2024" },
  { title: "OXYGEN", published: "19-04-2024" },
  { title: "PLUNGE", published: "20-04-2024" },
  { title: "REPAIR", published: "21-04-2024" },
  { title: "SCARCE", published: "22-04-2024" },
  { title: "TISSUE", published: "23-04-2024" },
  { title: "UNLOCK", published: "24-04-2024" },
  { title: "VENDOR", published: "25-04-2024" },
  { title: "WINTER", published: "26-04-2024" },
  { title: "ANCHOR", published: "27-04-2024" },
  { title: "BUBBLE", published: "28-04-2024" },
  { title: "CRAFTY", published: "29-04-2024" },
  { title: "DANCER", published: "30-04-2024" },
  { title: "FROSTY", published: "01-05-2024" },
  { title: "GOBLET", published: "02-05-2024" },
  { title: "HELMET", published: "03-05-2024" },
  { title: "INSECT", published: "04-05-2024" },
  { title: "JUNGLE", published: "05-05-2024" },
  { title: "KITTEN", published: "06-05-2024" },
  { title: "LUXURY", published: "07-05-2024" },
  { title: "MUFFIN", published: "08-05-2024" },
  { title: "NARROW", published: "09-05-2024" },
  { title: "OUTFIT", published: "10-05-2024" },
  { title: "POUNCE", published: "11-05-2024" },
  { title: "ROBUST", published: "12-05-2024" },
  { title: "SMOOTH", published: "13-05-2024" },
  { title: "UNISON", published: "14-05-2024" },
  { title: "WALRUS", published: "15-05-2024" },
  { title: "WONDER", published: "16-05-2024" },
  { title: "ADVICE", published: "17-05-2024" },
  { title: "CLIMAX", published: "18-05-2024" },
  { title: "DONATE", published: "19-05-2024" },
  { title: "EXPIRE", published: "20-05-2024" },
  { title: "FROZEN", published: "21-05-2024" },
  { title: "GINGER", published: "22-05-2024" },
  { title: "HUMANE", published: "23-05-2024" },
  { title: "JESTER", published: "24-05-2024" },
  { title: "LAGOON", published: "25-05-2024" },
  { title: "MUFFLE", published: "26-05-2024" },
  { title: "NESTED", published: "27-05-2024" },
  { title: "ORPHAN", published: "28-05-2024" },
  { title: "PRINCE", published: "29-05-2024" },
  { title: "FACADE", published: "30-05-2024" },
  { title: "RUMBLE", published: "31-05-2024" },
  { title: "SLEEVE", published: "01-06-2024" },
  { title: "TENDER", published: "02-06-2024" },
  { title: "WRENCH", published: "03-06-2024" },
  { title: "YEASTY", published: "04-06-2024" },
  { title: "ZIPPER", published: "05-06-2024" },
  { title: "ABRUPT", published: "06-06-2024" },
  { title: "BROKEN", published: "07-06-2024" },
  { title: "CEMENT", published: "08-06-2024" },
  { title: "DRIVEN", published: "09-06-2024" },
  { title: "FOLIAR", published: "10-06-2024" },
  { title: "GRAVEL", published: "11-06-2024" },
  { title: "HEATER", published: "12-06-2024" },
  { title: "IMPALE", published: "13-06-2024" },
  { title: "JUMBLE", published: "14-06-2024" },
  { title: "MINGLE", published: "15-06-2024" },
  { title: "OBTAIN", published: "16-06-2024" },
  { title: "PISTON", published: "17-06-2024" },
  { title: "QUIVER", published: "18-06-2024" },
  { title: "RECESS", published: "19-06-2024" },
  { title: "SCRIPT", published: "20-06-2024" },
  { title: "TUMBLE", published: "21-06-2024" },
  { title: "UNFOLD", published: "22-06-2024" },
  { title: "PRINCE", published: "23-06-2024" },
  { title: "ZANIER", published: "24-06-2024" },
  { title: "AMUSED", published: "25-06-2024" },
  { title: "CHILLS", published: "26-06-2024" },
  { title: "ELIXIR", published: "27-06-2024" },
  { title: "FONDUE", published: "28-06-2024" },
  { title: "GLOWED", published: "29-06-2024" },
  { title: "HAMMER", published: "30-06-2024" },
  { title: "ABROAD", published: "01-07-2024" },
  { title: "BEFALL", published: "02-07-2024" },
  { title: "CORNER", published: "03-07-2024" },
  { title: "DEBRIS", published: "04-07-2024" },
  { title: "EMBODY", published: "05-07-2024" },
  { title: "FABRIC", published: "06-07-2024" },
  { title: "HICCUP", published: "07-07-2024" },
  { title: "IMPACT", published: "08-07-2024" },
  { title: "JIGSAW", published: "09-07-2024" },
  { title: "MIRROR", published: "10-07-2024" },
  { title: "NOVICE", published: "11-07-2024" },
  { title: "OPIATE", published: "12-07-2024" },
  { title: "PIGLET", published: "13-07-2024" },
  { title: "QUIRKY", published: "14-07-2024" },
  { title: "REJOIN", published: "15-07-2024" },
  { title: "HACKED", published: "16-07-2024" },
  { title: "THRIVE", published: "17-07-2024" },
  { title: "UNEASE", published: "18-07-2024" },
  { title: "VERIFY", published: "19-07-2024" },
  { title: "WALNUT", published: "20-07-2024" },
  { title: "YACHTS", published: "21-07-2024" },
  { title: "BANNER", published: "22-07-2024" },
  { title: "CYPHER", published: "23-07-2024" },
  { title: "DECENT", published: "24-07-2024" },
  { title: "ENCODE", published: "25-07-2024" },
  { title: "FACADE", published: "26-07-2024" },
  { title: "GOSSIP", published: "27-07-2024" },
  { title: "HERALD", published: "28-07-2024" },
  { title: "INJECT", published: "29-07-2024" },
  { title: "JOUNCE", published: "30-07-2024" },
  { title: "LEGION", published: "31-07-2024" },
  { title: "NIMBLE", published: "01-08-2024" },
  { title: "OUTRUN", published: "02-08-2024" },
  { title: "PENCIL", published: "03-08-2024" },
  { title: "REWIND", published: "04-08-2024" },
  { title: "SHIVER", published: "05-08-2024" },
  { title: "TANGLE", published: "06-08-2024" },
  { title: "WISDOM", published: "07-08-2024" },
  { title: "ARCADE", published: "08-08-2024" },
  { title: "BLOTCH", published: "09-08-2024" },
  { title: "CANKER", published: "10-08-2024" },
  { title: "FONDLE", published: "11-08-2024" },
  { title: "GROVEL", published: "12-08-2024" },
  { title: "HALVED", published: "13-08-2024" },
  { title: "REMARK", published: "16-08-2024" },
  { title: "MEMBER", published: "17-08-2024" },
  { title: "BUSTLE", published: "18-08-2024" },
  { title: "FORGED", published: "19-08-2024" },
  { title: "BIGGER", published: "20-08-2024" },
  { title: "REASON", published: "21-08-2024" },
  { title: "MANAGE", published: "22-08-2024" },
  { title: "LOVELY", published: "23-08-2024" },
  { title: "CUSTOM", published: "24-08-2024" },
  { title: "TARGET", published: "25-08-2024" },
  { title: "TALENT", published: "26-08-2024" },
  { title: "ZYGOTE", published: "27-08-2024" },
  { title: "CREATE", published: "28-08-2024" },
  { title: "WARMTH", published: "29-08-2024" },
  { title: "SELECT", published: "30-08-2024" },
  { title: "MIDDLE", published: "31-08-2024" },
  { title: "MODERN", published: "01-09-2024" },
  { title: "GROWTH", published: "02-09-2024" },
  { title: "SAVIOR", published: "03-09-2024" },
  { title: "SEQUEL", published: "04-09-2024" },
  { title: "POTATO", published: "05-09-2024" },
  { title: "MYSTIC", published: "06-09-2024" },
  { title: "WALKER", published: "07-09-2024" },
  { title: "SOURCE", published: "08-09-2024" },
  { title: "FOLDER", published: "09-09-2024" },
  { title: "REPORT", published: "10-09-2024" },
  { title: "REVIEW", published: "11-09-2024" },
  { title: "NEUTER", published: "12-09-2024" },
  { title: "SOCKET", published: "13-09-2024" },
  { title: "STREET", published: "14-09-2024" },
  { title: "SLOVEN", published: "15-09-2024" },
  { title: "PRAISE", published: "16-09-2024" },
  { title: "ENERGY", published: "17-09-2024" },
  { title: "PATROL", published: "18-09-2024" },
  { title: "YAWNER", published: "19-09-2024" },
  { title: "PLAQUE", published: "20-09-2024" },
  { title: "TURBOS", published: "21-09-2024" },
  { title: "BUFFER", published: "22-09-2024" },
  { title: "INSIDE", published: "23-09-2024" },
  { title: "SILENT", published: "24-09-2024" },
  { title: "BUTTON", published: "25-09-2024" },
  { title: "GATHER", published: "26-09-2024" },
  { title: "LISTEN", published: "27-09-2024" },
  { title: "FOREST", published: "28-09-2024" },
  { title: "FENDER", published: "29-09-2024" },
  { title: "RELIEF", published: "30-09-2024" },
  { title: "PICKLE", published: "01-10-2024" },
  { title: "VIOLET", published: "02-10-2024" },
  { title: "PURITY", published: "03-10-2024" },
  { title: "RANKED", published: "04-10-2024" },
  { title: "SUMMER", published: "05-10-2024" },
  { title: "STAGES", published: "06-10-2024" },
  { title: "PILLOW", published: "07-10-2024" },
  { title: "ESCAPE", published: "08-10-2024" },
  { title: "MINUTE", published: "09-10-2024" },
  { title: "LEAVES", published: "10-10-2024" },
  { title: "SQUARE", published: "11-10-2024" },
  { title: "ALMOND", published: "12-10-2024" },
  { title: "COURSE", published: "13-10-2024" },
  { title: "ACCUSE", published: "14-10-2024" },
  { title: "SNEEZE", published: "15-10-2024" },
  { title: "DEVICE", published: "16-10-2024" },
  { title: "TEMPER", published: "17-10-2024" },
  { title: "CHOICE", published: "18-10-2024" },
  { title: "SPROUT", published: "19-10-2024" },
  { title: "MELLOW", published: "20-10-2024" },
  { title: "DESERT", published: "21-10-2024" },
  { title: "PICKED", published: "22-10-2024" },
  { title: "SHIELD", published: "23-10-2024" },
  { title: "SLIGHT", published: "24-10-2024" },
  { title: "FRIEND", published: "25-10-2024" },
  { title: "MANNER", published: "26-10-2024" },
  { title: "BRIGHT", published: "27-10-2024" },
  { title: "FRAMES", published: "28-10-2024" },
  { title: "MODERN", published: "29-10-2024" },
  { title: "DIALOG", published: "30-10-2024" },
  { title: "ALWAYS", published: "31-10-2024" },
  { title: "CREDIT", published: "01-11-2024" },
  { title: "RETURN", published: "02-11-2024" },
  { title: "BORDER", published: "03-11-2024" },
  { title: "JUNIOR", published: "04-11-2024" },
  { title: "TUNNEL", published: "05-11-2024" },
  { title: "EDITOR", published: "06-11-2024" },
  { title: "GAMBLE", published: "07-11-2024" },
  { title: "CHERRY", published: "08-11-2024" },
  { title: "PEARLS", published: "09-11-2024" },
  { title: "WINNER", published: "10-11-2024" },
  { title: "GLANCE", published: "11-11-2024" },
  { title: "SHELVE", published: "12-11-2024" },
  { title: "ZONKED", published: "13-11-2024" },
  { title: "DESIRE", published: "14-11-2024" },
  { title: "GLANCE", published: "15-11-2024" },
  { title: "SYSTEM", published: "16-11-2024" },
  { title: "SHADOW", published: "17-11-2024" },
  { title: "BOTTLE", published: "18-11-2024" },
  { title: "VALLEY", published: "19-11-2024" },
  { title: "METHOD", published: "20-11-2024" },
  { title: "WINTRY", published: "21-11-2024" },
  { title: "VENTED", published: "22-11-2024" },
  { title: "CARPET", published: "23-11-2024" },
  { title: "FOLLOW", published: "24-11-2024" },
  { title: "DUTIES", published: "25-11-2024" },
  { title: "BONDED", published: "26-11-2024" },
  { title: "ARTIST", published: "27-11-2024" },
  { title: "CRADLE", published: "28-11-2024" },
  { title: "BRIDGE", published: "29-11-2024" },
  { title: "DOUBLE", published: "30-11-2024" },
  { title: "DEPART", published: "01-12-2024" },
  { title: "DEBATE", published: "02-12-2024" },
  { title: "FIGURE", published: "03-12-2024" },
  { title: "FEMALE", published: "04-12-2024" },
  { title: "FREEZE", published: "05-12-2024" },
  { title: "GADGET", published: "06-12-2024" },
  { title: "FIXING", published: "07-12-2024" },
  { title: "BEHIND", published: "08-12-2024" },
  { title: "EXPORT", published: "09-12-2024" },
  { title: "AROUND", published: "10-12-2024" },
  { title: "ENSURE", published: "11-12-2024" },
  { title: "CREDIT", published: "12-12-2024" },
  { title: "BLAZER", published: "13-12-2024" },
  { title: "CAUGHT", published: "14-12-2024" },
  { title: "BALLET", published: "15-12-2024" },
  { title: "DURING", published: "16-12-2024" },
  { title: "ABSENT", published: "17-12-2024" },
  { title: "FACTOR", published: "18-12-2024" },
  { title: "ENTIRE", published: "19-12-2024" },
  { title: "CLIQUE", published: "20-12-2024" },
  { title: "COMING", published: "21-12-2024" },
  { title: "CAUGHT", published: "22-12-2024" },
  { title: "BACKUP", published: "23-12-2024" },
  { title: "CREDIT", published: "24-12-2024" },
  { title: "BOILER", published: "25-12-2024" },
  { title: "FARMER", published: "26-12-2024" },
  { title: "FINDER", published: "27-12-2024" },
  { title: "BROKER", published: "28-12-2024" },
  { title: "COMBAT", published: "29-12-2024" },
  { title: "FOUGHT", published: "30-12-2024" },
  { title: "COFFEE", published: "31-12-2024" },
  { title: "DRIVER", published: "01-01-2025" },
  { title: "EMBRYO", published: "02-01-2025" },
  { title: "ALIGHT", published: "03-01-2025" },
  { title: "CURSOR", published: "04-01-2025" },
  { title: "DELUGE", published: "05-01-2025" },
  { title: "GAMBLE", published: "06-01-2025" },
  { title: "COOKER", published: "07-01-2025" },
  { title: "DECIDE", published: "08-01-2025" },
  { title: "COWARD", published: "09-01-2025" },
  { title: "COLOUR", published: "10-01-2025" },
  { title: "DELETE", published: "11-01-2025" },
  { title: "FLIGHT", published: "12-01-2025" },
];
