import React from 'react';

function footer() {
  return (
    <footer className='footer'>
      <small><span className='copyright'>&copy; Lawrence Monk</span> | <span className='version'>Alpha v0.1004</span></small>
    </footer>
  );
}

export default footer;
